<template>
  <!--
    Enables upload of files for resort logo
  -->
  <div class="resortLogoUploader">
    <Portlet
      icon="upload"
    >
      <LoadingPlaceholder v-if="loading == true" />
      <div
        v-else
        class="row"
      >
        <div class="col-12 col-xl-3 mb-3">
          <Portlet
            :title="$t('staticResourceUploaderComponent.upload')"
            icon="upload"
          >      
            <div class="input-group mb-3">
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('type') }}</label>
              </div>
              <select
                v-model="imageType"
                class="form-control cursor-pointer rounded"
                @change="uploadPercentage = 0"
              >
                <option value="">
                  Please select Type
                </option>
                <option
                  v-for="(name, nameIndex) in imageTypes"
                  :key="`imageUploadImageNameSelectOption-${ nameIndex }`"
                  :value="name"
                >
                  {{ name }}
                </option>
              </select>
            </div>  
            <div
              v-if="imageType == 'Header'"
              class="input-group mb-3"
            >
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('name') }}</label>
              </div>
              <select
                v-model="imageName"
                class="form-control cursor-pointer rounded"
                @change="uploadPercentage = 0"
              >
                <option value="">
                  Please select Name
                </option>
                <option
                  v-for="(name, nameIndex) in imageNames.header"
                  :key="`imageUploadImageNameSelectOption-${ nameIndex }`"
                  :value="name"
                >
                  {{ name }}
                </option>
              </select>
            </div> 
            <div
              v-if="imageType == 'Resort'"
              class="input-group mb-3"
            >
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('name') }}</label>
              </div>
              <select
                v-model="imageName"
                class="form-control cursor-pointer rounded"
                @change="uploadPercentage = 0"
              >
                <option value="">
                  Please select Name
                </option>
                <option
                  v-for="(name, nameIndex) in imageNames.resort"
                  :key="`imageUploadImageNameSelectOption-${ nameIndex }`"
                  :value="name"
                >
                  {{ name }}
                </option>
              </select>
            </div>
            <div
              v-if="imageType == 'Panorama'"
              class="input-group mb-3"
            >
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('name') }}</label>
              </div>
              <select
                v-model="imageName"
                class="form-control cursor-pointer rounded"
                @change="uploadPercentage = 0"
              >
                <option value="">
                  Please select Name
                </option>
                <option
                  v-for="(name, nameIndex) in imageNames.panorama"
                  :key="`imageUploadImageNameSelectOption-${ nameIndex }`"
                  :value="name"
                >
                  {{ name }}
                </option>
              </select>
            </div>
            <div
              v-if="imageType == 'Panorama' && imageName == imageNames.panorama[0]"
              class="input-group mb-3"
            >
              <div class="input-prepend mr-3">
                <label>{{ $t('resortForm.autogenerateSizes') }}</label>
              </div>
              <input
                v-model="autogenerateSizes"
                class="form-control m-input col-1 mt-1"
                type="checkbox"
              >
            </div> 
            <div
              v-if="imageType == 'Resort' && imageName == 'original_logo.png'"
              class="input-group mb-3"
            >
              <div class="input-prepend mr-3">
                <label>{{ $t('resortForm.autogenerateSizes') }}</label>
              </div>
              <input
                v-model="autogenerateSizes"
                class="form-control m-input col-1 mt-1"
                type="checkbox"
              >
            </div> 
            <div
              v-if="imageType == 'Keycard'"
              class="input-group mb-3"
            >
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('name') }}</label>
              </div>
              <select
                v-model="imageName"
                class="form-control cursor-pointer rounded"
                @change="uploadPercentage = 0"
              >
                <option value="">
                  Please select Name
                </option>
                <option
                  v-for="(name, nameIndex) in skiticketTypes"
                  :key="`imageUploadImageNameSelectOption-${ nameIndex }`"
                  :value="name"
                >
                  {{ name }}
                </option>
              </select>
            </div>
            <div
              v-if="imageType == 'Skimovie - Speedcheck'"
              class="input-group mb-3"
            >
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('name') }}</label>
              </div>
              <select
                v-model="imageName"
                class="form-control cursor-pointer rounded"
                @change="uploadPercentage = 0"
              >
                <option value="">
                  Please select Name
                </option>
                <option
                  v-for="(name, nameIndex) in imageNames.skimovieSpeedcheck"
                  :key="`imageUploadImageNameSelectOption-${ nameIndex }`"
                  :value="name"
                >
                  {{ name }}
                </option>
              </select>
            </div>        
            <div class="input-group mb-3">
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('staticResourceUploaderComponent.file') }}</label>
              </div>
              <input
                ref="fileInput"
                type="file"
                class="form-control cursor-pointer rounded"
                @change="getFileFromInput($event)"
              >
            </div>
            
            <div
              v-if="uploadPercentage > 0"
              class="input-group mb-3"
            >
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('staticResourceUploaderComponent.progress') }}</label>
              </div>
              <div class="progress">
                <div
                  :aria-valuenow="uploadPercentage"
                  :style="`width:${ uploadPercentage }%;`"
                  class="progress-bar"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ uploadPercentage }} %
                </div>
              </div>
            </div>
            
            <template v-if="uploadPercentage == 0">
              <button
                v-if="imageName.length > 0 && image != null"
                class="btn btn-primary btn-block"
                @click="saveFile()"
              >
                <font-awesome-icon
                  class="mr-1"
                  icon="upload"
                />
                <span>
                  {{ $t('upload') }}
                </span>
              </button>
              <button
                v-else
                class="btn btn-primary btn-block cursor-not-allowed"
                disabled
              >
                <font-awesome-icon
                  class="mr-1"
                  icon="upload"
                />
                <span>
                  {{ $t('upload') }}
                </span>
              </button>
            </template>
          </Portlet>
        </div>
        <div class="col-12 col-xl-9">
          <Portlet
            :title="$t('staticResourceUploaderComponent.files')"
            icon="image"
          >
            <table
              v-if="files != null"
              class="defaultTable"
            >
              <colgroup>
                <col width="150">
                <col
                  class="hide-down-sm"
                  width="150"
                >
                <col
                  class="hide-down-xl"
                  width="150"
                >
                <col>
                <col
                  class="hide-down-sm"
                  width="100"
                >
                <col
                  class="hide-down-sm"
                  width="150"
                >
              </colgroup>
              <thead>
                <tr>
                  <th>{{ $t('name') }}</th>
                  <th class="hide-down-sm">
                    {{ $t('type') }}
                  </th>
                  <th class="hide-down-xl">
                    {{ $t('date') }}
                  </th>
                  <th>{{ $t('path') }}</th>
                  <th class="hide-down-sm">
                    {{ $t('preview') }}
                  </th>
                  <th class="hide-down-sm">
                    {{ $t('options') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(file, fileIndex) in files"
                  :key="`imageUploadImage-${ fileIndex }`"
                >
                  <td>{{ file.displayName }}</td>
                  <td class="hide-down-sm">
                    {{ file.type }}
                  </td>
                  <td
                    v-tooltip="dateTime_fromNow(file.lastModifiedDate)"
                    class="hide-down-xl"
                  >
                    {{ dateTime_dateTime(file.lastModifiedDate) }}
                  </td>
                  <td>
                    <a
                      :href="getPath(file.uri)"
                      target="_blank"
                      class="cursor-pointer"
                    >{{ getPath(file.uri) }}</a>
                  </td>
                  <td class="align-middle text-center hide-down-sm">
                    <img
                      v-if="file.uri.includes('.png') || file.uri.includes('.jpg') || file.uri.includes('.jpeg')"
                      :src="getPath(file.uri)"
                      :alt="file.displayName"
                      class="previewImage"
                    >
                    <font-awesome-icon
                      v-else-if="file.uri.includes('.zip')"
                      class="placeholderIcon"
                      icon="folder"
                    />
                  </td>
                  <td class="hide-down-sm">
                    <button
                      class="btn btn-sm btn-block btn-danger"
                      @click="deleteResource(file.displayName, file.type)"
                    >
                      <font-awesome-icon
                        class="mr-2"
                        icon="trash"
                      />
                      <span>{{ $t('delete') }}</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-else>
              {{ $t('noDataAvailable') }}
            </p>
          </Portlet>
        </div>
      </div>
    </Portlet>
  </div>
</template>

<script>
import numberConversionAdapter from '@/adapter/numberConversionAdapter.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "ResortLogoUploader",
  mixins: [
    dateTimeMixin,
    numberConversionAdapter
  ],
  props: {
    resortId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      uploadPercentage: 0,
      loading: true,
      files: null,
      image: null,
      imageName: '',
      imageNames: null,
      imageType: '',
      imageTypes: ['Resort', 'Keycard', 'Header', 'Panorama', 'Skimovie - Speedcheck'],
      skiticketTypes: [
        'ski_ticket.jpg',
        'ski_ticket_skiline_token.jpg',
        'ski_ticket_barcode.jpg',
        'ski_ticket_feratel.jpg',
        'ski_ticket_occ.jpg',
        'ski_ticket_occ_overlay.png',
        'ski_ticket_generic_nr.jpg',
        'ski_ticket_serial_number.jpg',
        'ski_ticket_pps.jpg',
        'ski_ticket_ps.jpg',
        'ski_ticket_wtp.jpg',
        'ski_ticket-BACK.jpg',
        'ski_ticket_2017.jpg',
        'ski_ticket_chip_number.jpg',
        'ski_ticket_swisspass.jpg',
        'ski_ticket_ticketcorner.jpg',
        'ski_ticket copy.jpg',
        'ski_ticket_barcode_20151228.jpg',
        'ski_ticket_20151228.jpg',
        'ski_ticket_wtp_wifi.jpg',
        'skipass_serial_number.png'
      ],
      autogenerateSizes: false
    }
  },
  watch: {
    imageType () {
      this.autogenerateSizes = false;
    }
  },
  created () {
    this.getData();
  },
  methods: {
    getPath (uri) {
      if(uri) {
        return "https://www.skiline.cc/shared" + uri.replace("/shared", "");
      }
    },
    getFileFromInput () {
      this.image = this.$refs.fileInput.files[0];
      this.uploadPercentage = 0;
    },
    async saveFile () {
      await this.uploadImage();
      this.getImageRessource();
    },
    async uploadImage () {
      this.uploadingRightNow = true;
      this.uploadPercentage = 0;
      let bodyFormData = new FormData();
      bodyFormData.append('formFile', this.image);

      let uploadRoute = '';
      if (this.resortId)
      {
        uploadRoute=`/Resort/UploadImage/${ this.resortId }/${ this.imageType }/${ this.imageName }/${ this.autogenerateSizes }`;
      }
      await this.axios.post(uploadRoute, 
        bodyFormData,
        { headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function ( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          }.bind(this)
        }
      )
      .then((response) => {
        if (response && response.status && response.status == 200) {
          this.$snotify.success(this.$t('staticResourceUploaderComponent.fileSavedMessage'), this.$t('staticResourceUploaderComponent.fileSavedTitle'));
          this.getData();
        }
      })
      .finally(() => {
        this.reloadResortCache();
        this.autogenerateSizes = false;
        this.uploadingRightNow = false;
        setTimeout(() => {
          this.uploadPercentage = 0;
        }, 2500);
      });

    },
    getData () {
      this.axios.all([
        this.axios.get(`/Resort/GetResortImages?id=${ this.resortId }`),
        this.axios.post(`/Resort/GetAllowedImageFileNames/${ this.resortId }`)
      ])
      .then(this.axios.spread((filesResponse, namesResponse) => {
        if (filesResponse != null && filesResponse.data != null) {
          this.files = filesResponse.data;
        }
        if (namesResponse != null && namesResponse.data != null) {
          this.imageNames = namesResponse.data;
        }
      }))
      .finally(() => {
        this.loading = false;
      });
    },
    deleteResource (imageName, imageType) {
      this.axios.delete(`/Resort/DeleteResortImage/${ this.resortId }/${ imageType }/${ imageName }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status == 200) {
            this.getData();
            this.$snotify.success(this.$t('staticResourceUploaderComponent.fileDeletedMessage'), this.$t('staticResourceUploaderComponent.fileDeletedTitle'));
          }
        });
    },
    async reloadResortCache () {
      this.axios.post(`/Resort/ReloadResortCache`)
      .then(() => {
      })
      .catch(() => {
        this.$snotify.success('Reload Resort Cache not successfull - changes are not yet applied to the system!');
      });
    }
  }
}
</script>

<style>
.resortLogoUploader input[type="file"] {
  height: 40px;
  padding-top: 8px;
  padding-left: 8px;
}
.resortLogoUploader .previewImage {
  width: 100%;
  height: auto;
  display: block;
}
.resortLogoUploader .placeholderIcon {
  font-size: 2rem;
}
.resortLogoUploader .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
